import { BeamHorizontalNavigation } from '../../../../../stories/BeamHorizontalNavigation'
import { BeamNavLinkProps } from '../../../../../stories/BeamHorizontalNavigation/BeamHorizontalNavigation'

export const DevConsoleTabs = () => {
  const devConsolePageLinks: BeamNavLinkProps[] = [
    {
      label: 'Staging',
      link: `/dev-console/info`,
      isActive: (match, location) => {
        const searchParam = new URLSearchParams(location.search)
        return ['staging', null].includes(searchParam.get('env'))
      },
    },
    {
      label: 'Production',
      link: `/dev-console/info?env=production`,
      isActive: (match, location) => {
        const searchParam = new URLSearchParams(location.search)
        return searchParam.get('env') === 'production'
      },
    },
  ]

  return (
    <div>
      <BeamHorizontalNavigation
        links={devConsolePageLinks}
        slotProps={{ link: { className: 'mx-0' }, container: { className: 'space-x-6' } }}
      />
    </div>
  )
}
