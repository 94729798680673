import { useEffect, useState } from 'react'

import { BeamButton } from '../../../../stories/BeamButton'
import { BeamModal } from '../../../../stories/BeamModal'
import { useGtagWithContext } from '../../../root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import image from './assets/firstTimeExperiencePreviewImage.png'
import $$ from './social-share-first-time-experience-modal.module.css'

export const SocialShareFirstTimeExperienceModal = ({
  onContinueHandler = () => {
    return
  },
}: {
  onContinueHandler?: () => void
}) => {
  const [open, setOpen] = useState(false)
  const gtag = useGtagWithContext()

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <BeamModal
      open={open}
      label={''}
      onCloseCallback={() => {
        gtag('first_time_promo_social_share_modal | close')
      }}
      body={
        <div className={$$.container}>
          <h1 className={$$.modalTitle} slot="label">
            <div className={$$.emoji}>🎉</div>
            Drive more customers to your campaign using our campaign social share feature!
          </h1>
          <p className={$$.modalDescription}>
            Design and upload your own custom social share assets for each boost to enable your
            customers to grow buzz organically around your campaign
          </p>

          <h2 className={$$.subheading}>What’s This?</h2>
          <div className={$$.twoColumnContainer}>
            <div className={$$.leftColumn}>
              <img src={image} alt="Sample of Social Share Assets" />
            </div>
            <div className={$$.rightColumn}>
              <ul className={$$.list}>
                <li>
                  You can upload a unique social share asset per nonprofit, per campaign, giving
                  your customers tailored, exciting content to boost your campaign’s reach.
                </li>
                <li>
                  How customers find these: After a customer selects a boosted nonprofit and checks
                  out, they can click “Share to boost impact” from the impact overview Beam
                  component.
                </li>
                <li>
                  Have questions?{' '}
                  <a href="/contact-support" target="_blank" rel="noopener noreferrer">
                    Contact Support
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
      footer={
        <div className={$$.container}>
          <BeamButton
            label="Add Social Assets"
            variant="basic"
            className={$$.button}
            onClick={async () => {
              gtag('first_time_promo_social_share_modal | go_to_upload')
              onContinueHandler()
            }}
          />
        </div>
      }
    />
  )
}
