import { SlCopyButton } from '@shoelace-style/shoelace/dist/react'
import { createColumnHelper } from '@tanstack/react-table'

import { BeamTable } from '../../../../../stories/BeamTable'

interface GenericResourceDetails {
  id: number
  name: string
}

export const DevConsoleTable = ({
  data,
  tableOptions,
}: {
  data: GenericResourceDetails[]
  tableOptions: {
    resourceNameColumnLabel?: string
    resourceIdColumnLabel?: string
  }
}) => {
  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('name', {
      header: tableOptions.resourceNameColumnLabel || 'Name',
      size: 500,
    }),
    columnHelper.accessor('id', {
      header: tableOptions.resourceIdColumnLabel || 'ID',
    }),
    columnHelper.display({
      id: 'Copy',
      header: '',
      enableSorting: false,
      cell: ({ row }) => {
        const rowData = row.original

        return (
          <div className={'text-lg text-center'}>
            <SlCopyButton copyLabel={'Copy ID to clipboard'} value={rowData.id.toString()} />
          </div>
        )
      },
    }),
  ]

  return (
    <>
      <BeamTable
        columns={columns}
        data={data}
        condensed={true}
        noDataMessage={'No nonprofits found'}
        enableSorting={false}
      />
    </>
  )
}
