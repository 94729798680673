import { truncate } from 'lodash'
import { HTMLAttributes, useRef, useState } from 'react'

import { BeamShoelaceProps } from '../interface'
import { FileUploadIcon } from './assets/FileUploadIcon'
import $$ from './beam-file-upload.module.css'

export interface BeamButtonProps extends BeamShoelaceProps, HTMLAttributes<any> {}

/**
 * File Upload component
 */
export const BeamFileUpload = ({
  defaultFileName,
  onChangeHandler,
  onClearHandler,
}: {
  defaultFileName?: string | null
  onChangeHandler?: (e: any) => void
  onClearHandler?: (e: any) => void
}) => {
  const [filename, setFileName] = useState(defaultFileName)
  const [newSubmission, setNewSubmission] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const fileExtension = filename && filename.includes('.') ? filename.split('.').reverse()[0] : ''

  const displayFileName = (e: any) => {
    const shouldWeContinue = !!onChangeHandler && onChangeHandler(e)
    if (!shouldWeContinue) return

    const filename = e.target.value

    setFileName(e.target.value)
    setNewSubmission(true)

    if (filename) {
      e.target.style = 'color: initial'
    } else {
      e.target.style = 'color: transparent'
    }
  }

  const clearFileName = (e: any) => {
    if (fileInputRef.current) {
      setFileName('')

      fileInputRef.current.value = ''
      // @ts-ignore
      fileInputRef.current.style = 'color: transparent'
    }

    !!onClearHandler && onClearHandler(e)
  }

  const file = filename ? filename.replace(/^.*[\\/]/, '') : ''

  return (
    <div className={$$.fileUploadContainer}>
      {!filename && <FileUploadIcon className={$$.fileUploadButton} />}
      {filename && (
        <button onClick={clearFileName} className={$$.clearButton}>
          <ClearIcon />
        </button>
      )}

      {!newSubmission && filename?.includes('.') && (
        <a href={filename} className={$$.existingFilename} target="_blank" rel="noreferrer">
          {truncate(file.split('.')[0], { length: 20 })}.{fileExtension}
        </a>
      )}

      <input
        ref={fileInputRef}
        type="file"
        className={$$.fileUploadWidget}
        style={{ color: 'transparent' }}
        onChange={displayFileName}
      />
    </div>
  )
}

const ClearIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 6L6 18"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
