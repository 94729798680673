import { useBeamSelector } from '../../../hooks'
import { BeamButton, BeamButtonProps } from '../../../stories/BeamButton/BeamButton'
import { TUser } from '../../../utils/types'

export function GrowWithBeamFeatureAccessButton(props: BeamButtonProps) {
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const GROW_WITH_BEAM_INELIGIBLE_MESSAGE = 'Available for grow with Beam accounts'
  const shouldLockButton = !user?.enhancedFeatures.permissions.social_share_upload.access

  return (
    <BeamButton
      {...props}
      variant={shouldLockButton ? 'locked' : props.variant}
      disabled={shouldLockButton}
      tooltipOptions={{
        tooltipPlacement: 'bottom',
        tooltip: shouldLockButton ? GROW_WITH_BEAM_INELIGIBLE_MESSAGE : undefined,
      }}
    />
  )
}
