import { useContext } from 'react'

import { BeamLoadingIndicator } from '../../../../stories/BeamLoadingIndicator'
import { CenteredContent } from '../../../root/CenteredContent'
import { DevConsoleTable } from './components/DevConsoleResourceIdTable'
import { DevConsoleTabs } from './components/DevConsoleTabs'
import { TextFieldWithCopyButton } from './components/TextFieldWithCopyButton'
import { DevConsoleInfoContext } from './page'

const TableTitle = ({ title }: { title: string }) => {
  return (
    <h3 className={'text-sky-800 font-primary text-md font-medium leading-8 mb-2 mt-0'}>{title}</h3>
  )
}

export const DevConsoleInfoContent = () => {
  const { dataRequest, activeTab } = useContext(DevConsoleInfoContext)
  const { loading } = dataRequest
  const data =
    activeTab === 'production' ? dataRequest?.data?.production : dataRequest?.data?.staging

  return (
    <>
      <div className={'bg-charcoal-50 px-5 flex flex-col beam--page-content--no-gutter'}>
        <div>
          <h1>API Keys & IDs</h1>
        </div>
        <div>
          <DevConsoleTabs />
        </div>
      </div>

      <section>
        {loading ? (
          <CenteredContent>
            <BeamLoadingIndicator />
          </CenteredContent>
        ) : (
          <div className={'flex flex-col max-w-[720px]'}>
            <div className={'mb-[56px] space-y-6'}>
              <h2>API Keys</h2>

              <TextFieldWithCopyButton
                label={'Storefront Component API Key'}
                value={'PLACEHOLDER_834h52y3485h589247y5h76ywhu4h8q'}
                onClickReload={() => {
                  // TODO -  trigger api key regeneration
                  return null
                }}
              />
              <TextFieldWithCopyButton
                label={'OMS Integration API Key'}
                value={'PLACEHOLDER_834h52y3485h589247y5h76ywhu4h8q'}
                onClickReload={() => {
                  // TODO -  trigger api key regeneration
                  return null
                }}
              />
            </div>

            <h2>Chain & Site IDs</h2>

            <div className={'mb-6'}>
              <TextFieldWithCopyButton label={'Chain ID'} value={dataRequest.data?.chainId || ''} />
            </div>

            <div className={'mb-[50px]'}>
              <TableTitle title={`Sites`} />
              <DevConsoleTable
                data={data?.stores || []}
                tableOptions={{
                  resourceNameColumnLabel: 'Site Name',
                  resourceIdColumnLabel: 'Site ID',
                }}
              />
            </div>

            {data?.stores.map(store => {
              return (
                <div key={store.id + store.name} className={'mb-8'}>
                  <TableTitle title={`${store.name}: Nonprofit IDs`} />
                  <DevConsoleTable
                    data={store.nonprofits}
                    tableOptions={{
                      resourceNameColumnLabel: 'Nonprofit Name',
                      resourceIdColumnLabel: 'Nonprofit ID',
                    }}
                  />
                </div>
              )
            })}
          </div>
        )}
      </section>
    </>
  )
}
