import { SlCopyButton, SlIconButton } from '@shoelace-style/shoelace/dist/react'
import { isNil } from 'lodash'

import { BeamTextfield } from '../../../../../stories/BeamTextfield'

interface TextFieldWithCopyButtonProps {
  label: string
  value: string | number
  /**
   * Function triggered when the "reload" button is clicked. "Reload" button is hidden when this prop is absent.
   */
  onClickReload?: () => void
}

export const TextFieldWithCopyButton = ({
  label,
  value,
  onClickReload,
}: TextFieldWithCopyButtonProps) => {
  const valueString = String(value)

  return (
    <div className={'flex flex-row justify-between items-center'}>
      <span className={'text-sky-800 font-normal'}>{label}</span>
      <div className={'w-[63%]'}>
        <BeamTextfield name={'chainId'} readonly={true} value={value ? String(value) : ''}>
          <div slot={'suffix'}>
            <SlCopyButton copyLabel={'Copy key to clipboard'} value={valueString} />
            {!isNil(onClickReload) && (
              <SlIconButton name={'reload'} library={'system'} onClick={onClickReload} />
            )}
          </div>
        </BeamTextfield>
      </div>
    </div>
  )
}
