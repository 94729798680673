import { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { BeamSEO } from '../../../root/BeamSEO'
import { DevConsoleResponse } from '../../../root/PartnerUploadsTable/types'
import { DevConsoleInfoContent } from './DevConsoleInfoContent'

const mockApiResponse: DevConsoleResponse = {
  chainId: 2,
  staging: {
    stores: [
      {
        id: 1,
        name: 'STAGING Arizona Bow Ties',
        nonprofits: [{ id: 17, name: 'STAGING TieTrends for Ties' }],
      },
    ],
  },
  production: {
    stores: [
      {
        id: 9,
        name: 'NYC Bow Ties',
        nonprofits: [
          { id: 18, name: 'BowKnots Harmony Foundation' },
          { id: 19, name: 'Tied Together for Change' },
          { id: 20, name: 'Knots of Kindness Alliance' },
          { id: 21, name: 'Bowties for a Better World' },
          { id: 22, name: 'Tangled Hearts Foundation' },
          { id: 25, name: 'Elegant Equality Fund' },
          { id: 27, name: 'BowTie Unites Us Foundation' },
          { id: 23, name: 'Loop of Love Charity' },
          { id: 26, name: 'Threads of Compassion Coalition' },
          { id: 24, name: 'BowTie Bonds for Humanity' },
        ],
      },
      { id: 10, name: 'Cali Bow Ties', nonprofits: [{ id: 17, name: 'TieTrends for Ties' }] },
      { id: 11, name: 'Arizona Bow Ties', nonprofits: [{ id: 17, name: 'TieTrends for Ties' }] },
    ],
  },
}

enum DevConsoleInfoTabs {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

interface DevConsoleInfoContextProps {
  dataRequest: {
    data: DevConsoleResponse | null
    loading: boolean
    error: string | null
  }
  activeTab: 'staging' | 'production'
}

export const DevConsoleInfoContext = createContext<DevConsoleInfoContextProps>({
  dataRequest: {
    data: null,
    loading: false,
    error: null,
  },
  activeTab: 'staging',
})

export const DevConsoleInfoPage = () => {
  const location = useLocation()

  const [activeTab, setActiveTab] = useState<DevConsoleInfoTabs>(DevConsoleInfoTabs.STAGING)
  const [dataRequest, setDataRequest] = useState<DevConsoleInfoContextProps['dataRequest']>({
    data: null,
    loading: false,
    error: null,
  })

  // determines which tab is active
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    setActiveTab(
      searchParams.get('env') === DevConsoleInfoTabs.PRODUCTION
        ? DevConsoleInfoTabs.PRODUCTION
        : DevConsoleInfoTabs.STAGING
    )
  }, [location.search])

  // fetch data
  useEffect(() => {
    if (!dataRequest.loading && !dataRequest.data && !dataRequest.error) {
      setDataRequest(prev => ({
        ...prev,
        loading: true,
      }))

      try {
        // TODO - Add data fetching logic here
        setDataRequest({
          data: mockApiResponse,
          error: null,
          loading: false,
        })
      } catch (error: any) {
        setDataRequest({
          data: null,
          error,
          loading: false,
        })
      }
    }
  }, [dataRequest.error, dataRequest.loading, dataRequest.data])

  return (
    <DevConsoleInfoContext.Provider
      value={{
        activeTab,
        dataRequest: dataRequest,
      }}>
      <BeamSEO title={'Developer Console'} />
      <main>
        <DevConsoleInfoContent />
      </main>
    </DevConsoleInfoContext.Provider>
  )
}
