import { SlTooltip } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import { ReactNode } from 'react'

import { TooltipPlacement } from '../BeamButton/BeamButton'
import { BeamDSProps } from '../interface'
import $$ from './beam-tooltip.module.css'

interface BeamTooltipProps extends BeamDSProps {
  /**
   * Content that shows up in tooltip
   */
  content: string | ReactNode
  /**
   * Content to target tooltip
   */
  children: string | ReactNode
  /**
   * Placement of tooltip
   */
  placement?: TooltipPlacement

  /**
   * Hoist the tooltip using a fixed positioning strategy, allowing the tooltip to overlay its parent component
   */
  hoist?: boolean
}

/**
 * Tooltip component
 */
export const BeamTooltip = ({
  content,
  children,
  placement,
  hoist = true,
  ...props
}: BeamTooltipProps) => {
  return (
    <SlTooltip
      {...props}
      hoist={hoist}
      placement={placement}
      className={cx($$.tooltip, props.className, 'beam--tooltip')}>
      <div slot="content">{content}</div>
      {children}
    </SlTooltip>
  )
}
