import axios from 'axios'
import { inRange } from 'lodash'

import { getV2ApiUrl } from '../../../../api/auth'
import { axiosRequest } from '../../../../utils/axiosRequest'
import { NonprofitFileEntry } from './promo.types'

export const listAllPromoAssets = async (promoId: number): Promise<NonprofitFileEntry[]> => {
  const url = `${getV2ApiUrl()}/campaigns/getAllPromoAssets/${promoId}`
  const res = await axiosRequest('GET', url)

  return res.data
}

export const postPromoAsset = async (
  filename: string,
  chainId: number,
  promoId: number,
  nonprofitId: number
): Promise<string> => {
  const url = `${getV2ApiUrl()}/campaigns/postPromoAsset/${chainId}/${promoId}/${nonprofitId}`
  const reqBody = { filename }
  const res = await axiosRequest('POST', url, reqBody)

  return res.data
}

export const uploadPromoAsset = async (
  presignedUploadUrl: string,
  file: File
): Promise<{ success: boolean }> => {
  // don't use "axiosRequest" because we don't want to send Beam credentials
  const res = await axios.put(presignedUploadUrl, file, {
    headers: {
      'content-type': file.type,
    },
  })

  return {
    success: inRange(res.status, 200, 300),
  }
}

export const deletePromoAsset = async (promoId: number, nonprofitId: number): Promise<string> => {
  const url = `${getV2ApiUrl()}/campaigns/deletePromoAsset/${promoId}/${nonprofitId}`
  const res = await axiosRequest('DELETE', url)

  return res.data
}
