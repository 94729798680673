import './beam-textfield.css'

import type SlInputElement from '@shoelace-style/shoelace/dist/components/input/input'
import { SlInput } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import React, { ChangeEventHandler, FocusEventHandler, forwardRef, ReactNode } from 'react'

import { BeamShoelaceProps } from '../interface'

export type BeamTextfieldEventTarget = SlInputElement
export type GenericInputChangeHandler = ChangeEventHandler<HTMLInputElement>
export type BeamInputChangeEvent = GenericInputChangeHandler

interface TextfieldProps extends BeamShoelaceProps {
  /**
   * Name of the field
   */
  name: string
  /**
   * Dictates whether the field should be used for a login form
   * (This is important for password managers to recognize the field since
   * Shoelace components don't register with most password managers.)
   */
  isPlainInput?: boolean
  /**
   * Text inside input box
   */
  placeholder?: string
  /**
   * Text below input box
   */
  helptext?: string
  /**
   * Text above input box
   */
  label?: string
  /**
   * Style of textfield
   */
  variant?: 'large' | 'small'
  /**
   * Is the textfield disabled
   */
  disabled?: boolean
  /**
   * Can the text field be autocompleted
   */
  autocomplete?: 'off' | 'on'
  /**
   * Type
   */
  type?: 'text' | 'password' | 'date' | 'datetime-local' | 'number' | 'email' | 'tel'
  /**
   * Trigger an autofocus on the element on load. Make sure to do this to only one field in the app.
   */
  autofocus?: boolean
  /**
   * onChange handler; Triggers whenever the field value has changed (on keypress).
   */
  // onChange?: BeamInputChangeEvent
  onChange?: BeamInputChangeEvent
  /**
   * onBlur handler; Triggers whenever the field loses focus.
   */
  onBlur?: FocusEventHandler<HTMLInputElement | SlInputElement>
  /**
   * The text value that shows up in the field
   */
  value?: string
  /**
   * The text value that shows up in the field
   */
  emptyLabel?: boolean
  /**
   * Minimum allowed value. Only applies to date and number input types.
   */
  min?: string | number
  /**
   * Maximum allowed value. Only applies to date and number input types.
   */
  max?: string | number
  /**
   * The maximum string length that the user can enter into a text input
   */
  maxLength?: number
  /**
   * Include time input along with date picker
   */
  includeTime?: boolean
  /**
   * Determines if the input is required
   */
  required?: boolean
  children?: ReactNode
  readonly?: boolean
}

/**
 * Primary UI component for user interaction
 */
export const BeamTextfield = forwardRef<SlInputElement | HTMLInputElement, TextfieldProps>(
  function BeamTextfield(
    {
      name,
      placeholder,
      onChange,
      onBlur,
      label,
      disabled,
      helptext,
      isPlainInput = false,
      autocomplete = 'on',
      type = 'text',
      variant = 'small',
      autofocus = false,
      value = undefined,
      emptyLabel = false,
      min,
      max,
      includeTime = false,
      required = false,
      children,
      maxLength,
      readonly = false,
      ...props
    },
    ref
  ) {
    let variantClassname

    switch (variant) {
      case 'large':
        variantClassname = 'beam--textfield--large'
        break

      case 'small':
        variantClassname = 'beam--textfield--small'
        break
    }

    const optionalProps: { value?: string | undefined } = {}
    if (value !== undefined) {
      optionalProps.value = value
    }

    if (isPlainInput) {
      return (
        <div
          className={cx('beam--textfield--base', { 'beam--textfield--empty-label': emptyLabel })}>
          <input
            ref={ref as React.Ref<HTMLInputElement>}
            disabled={disabled}
            name={name}
            className={cx('beam--textfield', variantClassname, props.className)}
            autoFocus={autofocus}
            type={type}
            autoComplete={autocomplete}
            placeholder={placeholder}
            onChange={onChange as GenericInputChangeHandler}
            onBlur={onBlur}
            required={required}
            maxLength={maxLength}
            {...optionalProps}
          />
        </div>
      )
    }

    return (
      <SlInput
        ref={ref as React.Ref<SlInputElement>}
        disabled={disabled}
        name={name}
        className={cx('beam--textfield', variantClassname, props.className)}
        autoFocus={autofocus}
        type={type}
        label={label}
        autocomplete={autocomplete}
        help-text={helptext}
        placeholder={placeholder}
        onSlInput={onChange as any}
        onBlur={onBlur}
        min={min}
        max={max}
        required={required}
        maxlength={maxLength}
        readonly={readonly}
        {...optionalProps}>
        {children}
      </SlInput>
    )
  }
)
