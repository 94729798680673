import type { IconLibrary } from '@shoelace-style/shoelace/dist/components/icon/library'
import systemLibrary from '@shoelace-style/shoelace/dist/components/icon/library.system'

/**
 * BEAM OVERRIDES for Shoelace System Icons
 * https://shoelace.style/components/icon#customizing-the-system-library
 * https://github.com/shoelace-style/shoelace/blob/next/src/components/icon/library.system.ts
 */
const icons = {
  // Used in dropdowns
  // Src: https://github.com/material-icons/material-icons/tree/master/svg/arrow_drop_down
  'chevron-down': `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M7 10l5 5l5-5H7z"/>
    </svg>
  `,
  // Used for close icons in modals, etc.
  // Src: https://github.com/material-icons/material-icons/tree/master/svg/close
  'x-lg': `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"/>
    </svg>
  `,
  reload: `
    <svg width="22" height="20" viewBox="0 0 22 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.14631 4.96599C6.38111 5.72012 5.79628 6.63747 5.43554 7.64945C5.0748 8.66144 4.94748 9.74188 5.06311 10.81C5.09652 11.1267 5.00278 11.4436 4.80249 11.6912C4.6022 11.9387 4.31178 12.0966 3.99511 12.13C3.67844 12.1634 3.36147 12.0697 3.11392 11.8694C2.86637 11.6691 2.70853 11.3787 2.67511 11.062C2.52184 9.63521 2.69283 8.19223 3.17532 6.84078C3.6578 5.48934 4.43931 4.26432 5.46151 3.25719C9.22471 -0.453207 15.2895 -0.390807 19.0083 3.37959C22.7283 7.15119 22.7055 13.2172 18.9435 16.9276C17.2018 18.6483 14.8635 19.6309 12.4155 19.6708C11.4903 19.6871 10.5676 19.5699 9.67591 19.3228C9.52052 19.284 9.37446 19.2146 9.24633 19.1185C9.11821 19.0224 9.01062 18.9016 8.9299 18.7633C8.84918 18.625 8.79696 18.4719 8.77633 18.3131C8.75569 18.1543 8.76706 17.9929 8.80975 17.8386C8.85244 17.6842 8.9256 17.54 9.02492 17.4143C9.12423 17.2887 9.24769 17.1842 9.38802 17.107C9.52835 17.0298 9.68271 16.9815 9.842 16.9649C10.0013 16.9483 10.1623 16.9638 10.3155 17.0104C10.985 17.196 11.6777 17.2837 12.3723 17.2708C14.2043 17.2419 15.9544 16.5068 17.2575 15.2188C20.0691 12.4468 20.0955 7.89999 17.2995 5.06559C14.5035 2.22999 9.95671 2.19399 7.14631 4.96599Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.76064 12.034C4.65586 12.1517 4.5289 12.2477 4.38704 12.3163C4.24517 12.385 4.09117 12.425 3.93382 12.4342C3.77648 12.4433 3.61887 12.4214 3.47001 12.3696C3.32115 12.3178 3.18395 12.2372 3.06624 12.1324L1.00224 10.2964C0.7645 10.0848 0.620572 9.78734 0.602118 9.46958C0.583665 9.15182 0.692197 8.83974 0.90384 8.602C1.11548 8.36426 1.4129 8.22033 1.73066 8.20188C2.04842 8.18343 2.3605 8.29196 2.59824 8.5036L4.66224 10.3396C4.77997 10.4444 4.87591 10.5713 4.94457 10.7132C5.01324 10.8551 5.05328 11.0091 5.06242 11.1664C5.07156 11.3238 5.04961 11.4814 4.99783 11.6302C4.94605 11.7791 4.86545 11.9163 4.76064 12.034Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90406 11.92C2.99861 12.0461 3.11707 12.1523 3.25267 12.2326C3.38827 12.3128 3.53835 12.3656 3.69436 12.3879C3.85036 12.4102 4.00922 12.4016 4.16188 12.3624C4.31454 12.3233 4.45799 12.2545 4.58406 12.16L6.98406 10.36C7.23867 10.169 7.40699 9.88475 7.452 9.56969C7.49701 9.25463 7.41502 8.93459 7.22406 8.67999C7.03311 8.42538 6.74883 8.25706 6.43377 8.21205C6.11871 8.16704 5.79867 8.24903 5.54406 8.43999L3.14406 10.24C3.01799 10.3345 2.91178 10.453 2.83149 10.5886C2.7512 10.7242 2.69841 10.8743 2.67612 11.0303C2.65384 11.1863 2.6625 11.3451 2.70161 11.4978C2.74072 11.6505 2.80951 11.7939 2.90406 11.92Z" fill="currentColor"/>
    </svg>

  `,
}

export const customSystemLibrary: IconLibrary = {
  name: 'system',
  resolver: (name: string) => {
    if (name in icons) {
      return `data:image/svg+xml,${encodeURIComponent(icons[name as keyof typeof icons])}`
    }
    return systemLibrary.resolver(name)
  },
}
