import cx from 'classnames'

import { BeamButton } from '../BeamButton'
import { BeamButtonProps, BeamButtonVariant } from '../BeamButton/BeamButton'
import { BeamCard } from '../BeamCard'
import { BeamDSProps } from '../interface'
import $$ from './beam-mini-cta.module.css'

interface BeamMiniCTAProps extends BeamDSProps {
  /**
   * Title of the card
   */
  title: string
  /**
   * Background color of the card. Potentials are based on colors defined in the branding guidelines (in other words, no custom hex values or RGBs)
   */
  description: string
  /**
   * The button label
   */
  buttonLabel: string
  /**
   * Background Color
   */
  backgroundColor?: string
  /**
   * Icon to display to the left
   */
  icon?: React.ReactNode
  /**
   * Disable the button
   */
  disabled?: boolean
  /**
   * Determines whether the button is full width (of parent container) or 60px shorter
   */
  variant?: 'basic' | 'mini'
  /**
   * Style of button
   */
  buttonVariant?: BeamButtonVariant
  buttonTooltip?: BeamButtonProps['tooltipOptions']
  /**
   * The button's click behavior
   */
  onClick?: () => void
}

/**
 * Mini CTA component
 */
export const BeamMiniCTA = ({
  title,
  description,
  buttonLabel,
  icon = '',
  disabled = false,
  variant = 'basic',
  buttonVariant = 'elevated',
  onClick,
  buttonTooltip,
  ...props
}: BeamMiniCTAProps) => {
  return (
    <BeamCard {...props} className={cx($$.miniCTA, props.className)}>
      <div className={$$.row}>
        <div className={$$.icon}>{icon}</div>
        <div className={$$.left}>
          <div className={$$.title}>{title}</div>
          <div className={$$.description}>{description}</div>
        </div>
        <div className={$$.right}>
          <BeamButton
            label={buttonLabel}
            variant={buttonVariant}
            onClick={onClick}
            disabled={disabled}
            tooltipOptions={buttonTooltip ? buttonTooltip : undefined}
            className={cx($$.button, {
              'beam--mini-cta--basic': variant === 'basic',
              'beam--mini-cta--mini': variant === 'mini',
            })}
          />
        </div>
      </div>
    </BeamCard>
  )
}
