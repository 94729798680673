import cx from 'classnames'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { BeamDSProps } from '../interface'

export interface BeamNavLinkProps {
  label: string
  link: string
  onClick?: () => void
  /**
   * A function to add extra logic for determining whether the link is active.
   * This should be used if you want to do more than verify that the link’s pathname matches the current URL’s pathname.
   * https://v5.reactrouter.com/web/api/NavLink/isactive-func
   */
  isActive?: NavLinkProps['isActive']
}

interface BeamHorizontalNavigationProps extends BeamDSProps {
  /**
   * List of links to display in the bar.
   */
  links: BeamNavLinkProps[]
  slotProps?: {
    [K in 'container' | 'link']?: {
      className?: string
    }
  }
}

/**
 * Beam Horizontal Navigation component
 */
export const BeamHorizontalNavigation = ({ links, slotProps }: BeamHorizontalNavigationProps) => {
  return (
    <div className={cx('py-7', slotProps?.container?.className)}>
      {links.map((link, i) => {
        const activeCssClasses = 'text-sky-500 border-b-2 border-b-sky-500 active'
        return (
          <NavLink
            key={i}
            exact
            isActive={link.isActive}
            to={link.link}
            onClick={link.onClick ? link.onClick : undefined}
            className={isActive => {
              return cx(
                'mt-0 mx-6 py-7 text-charcoal-500 text-sm font-black no-underline',
                slotProps?.link?.className,
                {
                  [activeCssClasses]: isActive,
                }
              )
            }}>
            {link.label}
          </NavLink>
        )
      })}
    </div>
  )
}
