import { useBeamSelector } from '../../../hooks'
import { BeamButton, BeamButtonProps } from '../../../stories/BeamButton/BeamButton'
import { TUser } from '../../../utils/types'
import { GWB_LOCKED_CTA_COPY } from '../NonprofitPartnersPage/CampaignsPage/CampaignPage'

export function EnhancedFeaturesLockedButton(props: BeamButtonProps) {
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const shouldLockButton = !user?.enhancedFeatures.permissions.campaign_action.access

  return (
    <BeamButton
      {...props}
      variant={shouldLockButton ? 'locked' : props.variant}
      disabled={shouldLockButton}
      tooltipOptions={{
        tooltip: shouldLockButton ? GWB_LOCKED_CTA_COPY : undefined,
      }}
    />
  )
}
