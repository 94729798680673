import './beam-button.css'

import SlButtonElement from '@shoelace-style/shoelace/dist/components/button/button'
import { SlButton } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import { isNil } from 'lodash'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { LockIcon } from '../../components/root/iconComponents/LockIcon'
import { BeamTooltip } from '../BeamTooltip'
import { BeamShoelaceProps } from '../interface'

export type BeamButtonVariant =
  | 'basic'
  | 'basic_blue'
  | 'neutral'
  | 'mini'
  | 'elevated'
  | 'white'
  | 'text'
  | 'input'
  | 'flat_white'
  | 'emphasis_white'
  | 'borderless_white'
  | 'locked'

export type TooltipPlacement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'

export interface BeamButtonProps extends BeamShoelaceProps, HTMLAttributes<any> {
  /**
   * Text for button (alternative to passing child element}
   */
  label?: string | ReactNode
  /**
   * Text for button (can also use label prop)
   */
  children?: string | ReactNode
  /**
   * Type of button
   */
  type?: 'button' | 'submit'
  /**
   * Style of button.
   * Note: The 'mini' variant will be deprecated in favor of "size" prop
   */
  variant?: BeamButtonVariant
  /**
   * Disable the button?
   */
  disabled?: boolean
  /**
   * Size of button
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Whether the button should display as a block or inline element.
   * Currently defaults to true, but will default to false in the future.
   * Can also be set via Tailwind `block` / `inline-block` class.
   */
  block?: boolean
  /**
   * Optional click handler
   */
  onClick?: () => void
  /**
   * The font weight for the button label. Overrides the default 900 (black) weight.
   */
  fontWeight?: 'normal' | 'medium'
  tooltipOptions?: {
    /**
     * Optional tooltip displayed on hover
     */
    tooltip?: ReactNode
    /**
     * Tooltip placement
     */
    tooltipPlacement?: TooltipPlacement
    /**
     * Whether to hoist the tooltip
     */
    tooltipHoist?: boolean
  }
}

const WithTooltip = ({
  tooltipOptions,
  children,
}: {
  tooltipOptions?: BeamButtonProps['tooltipOptions']
  children: ReactNode
}) => {
  if (!tooltipOptions || isNil(tooltipOptions.tooltip)) {
    return <>{children}</>
  }

  const { tooltip, tooltipPlacement, tooltipHoist = true } = tooltipOptions

  return (
    <BeamTooltip content={tooltip} placement={tooltipPlacement} hoist={tooltipHoist}>
      {children}
    </BeamTooltip>
  )
}

/**
 * Primary button component
 */
export const BeamButton = forwardRef<SlButtonElement, BeamButtonProps>(function BeamButton(
  {
    children,
    label,
    onClick,
    type = 'button',
    variant = 'basic',
    disabled = false,
    size = 'medium',
    block = true,
    tooltipOptions,
    ...props
  },
  ref
) {
  const variantClassname = `beam--button--${variant}`
  const buttonSize = variant === 'mini' ? 'small' : size

  const LockedIconPrefix = () => {
    if (variant !== 'locked') return null

    return (
      <div className={'w-[11px] inline-block mr-1'}>
        <LockIcon />
      </div>
    )
  }

  return (
    <WithTooltip tooltipOptions={tooltipOptions}>
      <SlButton
        {...props}
        ref={ref}
        type={type}
        slot={props.slot}
        variant={variant === 'text' ? 'text' : undefined}
        className={cx(
          'beam--button',
          variantClassname,
          {
            ['beam--button--block']: block,
            [`font-${props.fontWeight}`]: !!props.fontWeight,
          },
          props.className
        )}
        size={buttonSize}
        disabled={disabled}
        onClick={onClick}>
        <LockedIconPrefix />
        {label || children || null}
      </SlButton>
    </WithTooltip>
  )
})
